import { Component, useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';
import store from 'features/store';
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from 'features/components/base/auth-pages/MyAccount/MyAccountMenuList';
import axios from "axios";
import './PremierFaqs.scss';
import { Button, Form } from "react-bootstrap";
import { FAQList, jiraKnowledge,premierFAQ } from "shared/settings/apiconfig";
import { getBuyerHeaders, getBuyersUrl, getSellerUrl } from "shared/http";
import { isAuthorized } from "shared/settings/auth";
import { addDataLayer } from "shared/utils/utils";
import { Helmet } from "react-helmet";

const PremierFaqsfn = (props) => {

  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchData, setSearchData] = useState('');
  const [hideContent, setHideContent] = useState(false);
  const [openIframeData, setOpenIframeData] = useState('');
  const [showSearchData, setShowSearchData] = useState(false);
  const [keyList, setKeyList] = useState([]);
  const [premierData, setPremierData] = useState({});
  const [openedKey, setOpenedKey] = useState(null); 
  const [scrollCheck, setScrollCheck] = useState(true)
  const onLoad = useRef(true);

  // pagination details
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => { 
    if(isAuthorized()) {
      getKeyList();
    } else {
      store.dispatch({ type: 'TOGGLE_SIGNIN_MODAL', payload: { open: true, mode: 'Sign-In' } });
    }
  }, []);
  useEffect(() => {

    if (premierData) {
      setTimeout(() => {
        let doc = document.getElementsByClassName("membership-click-here")
        for (let addfn of doc) {
          addfn.onclick = null;
          addfn.addEventListener('click', function () {
           props.history.push('/my-account/premier/member-benefits')
          })
        }

      }, 500);
    }
  }, [premierData])

  useEffect(() => {
    let dataLayerData = {
      'event': 'premier_faq',
      'buyr_id': props.user.id
    }
    addDataLayer(dataLayerData);
  }, []);

  // Get keylist for FAQ
  const getKeyList = () => {
    axios.get(getSellerUrl(`${FAQList}`)).then(response => {
      let data = response.data.data["searchwords"];
      setKeyList(data);
    }).catch(error => { });
  }

  const textChange = (name, keySetData) => {
  
    if (keySetData) {
      axios.get(getBuyersUrl(`${premierFAQ}${keySetData}`), getBuyerHeaders())
        .then(response => {
          setPremierData(prevData => ({
            ...prevData,
            [keySetData]: response.data
          }));
          setOpenedKey(prevKey => (prevKey === keySetData ? null : keySetData)); // Toggle opened key
       
        })
    }
  };

  // Function to handle search
  const handleSearch = (data, keySetData) => {
    setLoading(true);
    setShowSearchData(true);
    if (keySetData) {
      axios.get(getBuyersUrl(`${premierFAQ}${keySetData}`), getBuyerHeaders()).then(response => {
        setSearchData(response.data);
        setLoading(false);
      }).catch(error => {
        setShowSearchData(false);
        setLoading(false);
      })
    }
    else {
      let keyword = typeof (data) == 'string' ? data : searchText
      axios.get(getBuyersUrl(`${jiraKnowledge}${keyword}`), getBuyerHeaders()).then(response => {
        setSearchData(response.data.data.values);
        setShowSearchData(true);
        setLoading(false);
      }).catch(error => {
        setShowSearchData(false);
        setLoading(false);
      })

    }
  };

  // Logic to slice data array based on current page
  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = searchData?.values?.slice(indexOfFirstItem, indexOfLastItem);

  const handleInputChange = event => {
    setSearchText(event.target.value);
  };

  const checkTitle = (data) => {
    return data.replace(/@@@hl@@@/gi, `<b>`).replace(/@@@endhl@@@/gi, `</b>`)
  }

  const checkContent = (data) => {
    return data.replace(/@@@hl@@@/gi, `<b>`).replace(/@@@endhl@@@/gi, `</b>`)
  }

  const clearSearch = () => {
    setSearchText('');
    setSearchData('');
    setShowSearchData(false);
  }

  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  const openIframeContent = (data) => {
    setShowSearchData(false);
    setHideContent(true);
    setOpenIframeData(data.content.iframeSrc);
    setScrollCheck(false)
  }

  const navigatetoBack = () => {
    setHideContent(false);
  }

  const populateData = (data, keySetData) => {
    setSearchText(data);
      handleSearch(data, keySetData);
  }

  return (
    <>
      <Helmet>
        <title>CTBids | Member Service</title>
      </Helmet>
      <div className={`mt2 h-100 ${scrollCheck ? 'overflow-auto' : ''}`}>
        {hideContent ?
          <div className="d-flex search-header-content p-10">
            <div className="p-2">
              <b style={{ backgroundColor: '#A50050', border: '1px solid #A50050', color: 'white' }} className="clickable p-2" onClick={() => { navigatetoBack() }}>
                <i className="fas fa-chevron-left" /> Back
              </b>
            </div>
          </div> :
          <>
            <div className='d-flex flex-column' style={{ padding: "30px" }}>
              <div className="d-flex align-items-center">
                <img src={`${process.env.PUBLIC_URL}/static/images/Premier-Service.png`} className="logo-img" alt="member-service" />
                <h1 className="font-weight-bold p-2 ft-navy">Premier Member Service</h1>
              </div>
              <div className="mt-3">
                <div className="fs-17">
                  The Premier Member Service benefit ensures that you will have direct access to our experienced support team 24/7.
                </div>
                <div className="fs-17">The dedicated phone line and email below are only for CTBids Premier Members.</div>
                <div className="d-flex align-items-center mt-5">
                  <div className="d-flex contact-box" style={{ backgroundColor: "rgb(205, 84, 91)" }}></div>
                  <a href="tel:833-287-7364" className="font-weight-bold ft-navy fs-17">Call: 1-833-CTPremier (833-287-7364)</a>
                </div>
                <div className="d-flex align-items-center mt-1">
                  <div className="d-flex contact-box" style={{ backgroundColor: "rgb(29, 130, 150)" }}></div>
                  <a href='mailto:Premier@CTBids.com' className="font-weight-bold ft-navy fs-17">Email: Premier@CTBids.com</a>
                </div>
              </div>
              <div className="d-flex h-100 font-weight-bold fs-20 mt-5">
                Search Frequently Asked Questions & Answers
              </div>
              <div className="d-flex mt-4 flex-column">
                <div className='search-box d-flex'>
                  <div className="input-group">
                    <Form.Control className="search-input-box" type="text" id="search" aria-label='Search by text' name="search" placeholder='Search by name' onChange={handleInputChange} onKeyDown={handleKeyDown} value={searchText} />
                    <span className="input-group-append align-items-center clickable" onClick={handleSearch}>
                      <i className="fa fa-search"></i>
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <Button className="btn-secondary" style={{ marginLeft: "20px" }} onClick={handleSearch} disabled={!searchText}>Search</Button>
                    <Button className="clear-btn" style={{ marginLeft: "20px" }} onClick={clearSearch}>Clear</Button>
                  </div>
                </div>

                {!showSearchData ?
                  <div className="d-flex flex-column mt-3">
                    {keyList.map((data, index) => {
                      const key = data.topic.templatekey;
                      const content = premierData[key];
                      const isVisible = openedKey === key; 

                      return (
                        <div key={index}>
                          <ul className="plus">
                            <li
                              className="clickable"
                              onClick={() => textChange(data.topic.name, key)}
                            >
                              {"+ " + data.topic.name}
                            </li>
                          </ul>
                          {isVisible && content && (
                            <div className="content">
                              <div className="" dangerouslySetInnerHTML={{ __html: content }}></div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div> : ''
                }
              </div>

            </div>
          </>
        }

        {showSearchData && searchData && !loading && !hideContent ?
          <div className='d-flex flex-column' name="content-view">
            {
              searchData.length > 0 ?
                <div className='pt-2'>
                  {
                    searchData?.map((data, index) => {
                      return (
                        <div key={index} className='p-3'>
                          <tr className='d-flex flex-column'>
                            <td className="fs-20" style={{ color: '#00263e' }} dangerouslySetInnerHTML={{ __html: checkTitle(data.title) }}></td>
                            <td className="clickable p-2" style={{ color: '#A50050' }} dangerouslySetInnerHTML={{ __html: checkContent(data.excerpt) }} onClick={() => { openIframeContent(data); }}></td>
                          </tr>
                        </div>
                      );
                    })
                  }
                </div> :
                  <div className="d-flex align-items-center justify-content-center fs-20">
                    No Search Result Found
                  </div>
            }
          </div> : loading ?
            <div className="loader d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
              <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
            </div> : hideContent ?
              <div className="d-flex flex-column h-100">
                <div className="pt-3 h-100 mb-5" id="content-view">
                  <iframe className="iframe-border" src={`${openIframeData}`} width="100%" height="100%"
                    style={{ border: 'none' }}
                  ></iframe>
                </div>
              </div>
              : ''
        }
      </div>
    </>
  );
};

class PremierFaqs extends Component {
  constructor(props) {
    super(props);
    this.buyrId = this.props.user.id
  }
  
  componentDidMount() {
    store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
    ToggleSideBar();
    window.addEventListener('resize', ToggleSideBar);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', ToggleSideBar);
    removeSideBar();
  }

  render() {
    return <PremierFaqsfn {...this.props} />
  }
}

export default connect(state => state)(PremierFaqs);
