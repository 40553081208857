import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import store from "features/store";
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from "features/components/base/auth-pages/MyAccount/MyAccountMenuList";
import pmservice from "shared/services/pmservice";
import { isAuthorized, logout } from "shared/settings/auth";
import './MyPrimerAccount.scss'
import moment from "moment";
import { addDataLayer } from "shared/utils/utils";
import { Helmet } from "react-helmet";

const MyPrimerAccountfn = (props) => {
  const [loading, setLoading] = useState(true)
  const [myPrimerAccount, setMyPrimerAccount] = React.useState([]);
  const [membershipdata, setMembershipdata] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if(isAuthorized()) {
      if (props.user.id) {
        getMembershipDetails();
      } else {
        store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
        logout(props.history, false);
      }
    } else {
      store.dispatch({ type: 'TOGGLE_SIGNIN_MODAL', payload: { open: true, mode: 'Sign-In' } });
    }
  }, [props.user.id]);


  useEffect(() => {
    if (membershipdata.id) {
      getMyPremierAccountData();
    }
  }, [membershipdata.id]);

  useEffect(() => {
    let dataLayerData = {
      'event': 'premier_account',
      'buyr_id': props.user.id
    }
    addDataLayer(dataLayerData);
  }, []);

  const getMyPremierAccountData = () => {
    pmservice.getContent("premier", "").then((data) => {
      let myPremierAccountData = JSON.parse(data.data.data[0].jsoncontent);
      let myPremierAccountValue = myPremierAccountData;
      let startDate = moment(membershipdata.startdate).format("MMMM DD, YYYY");
      let endDate = moment(membershipdata.enddate).format("MMMM DD, YYYY");
      myPremierAccountValue.body.map((data, index) => {
        if (data?.description && Array.isArray(data.description)) {
          let replaceContent = data.description;
          replaceContent.map((repData, repIndex) => {
            if (repData.includes("STARTDATEPLACEHOLDER")) {
              myPremierAccountValue.body[index].description[repIndex] = repData.replace("STARTDATEPLACEHOLDER", startDate);
            } else if (repData.includes("ENDDATEPLACEHOLDER")) {
              myPremierAccountValue.body[index].description[repIndex] = repData.replace("ENDDATEPLACEHOLDER", endDate);
            }
          })
        }
      })
      setMyPrimerAccount(myPremierAccountValue);
      setLoading(false);
    })
      .catch((error) => {
        if (error?.status === 401) {
          store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
          logout(props.history, false);
          setLoading(false)
        }
      });
  };


  const getMembershipDetails = () => {
    const data = {
      "field":["enddate","autorenewal","buyerid","id","startdate"]
    };
    return pmservice.getMembershipDetail(data)
      .then((res) => {
        if (res.data.data.length > 0) {
          const membershipData = res.data.data[0];
          setMembershipdata(membershipData);
        } else {
          setErrorMessage("No active membership exists and account expired");
          setLoading(false);
        }
      })
      .catch((error) => {
      });
  }

  const handleNavigation = (path, flag) => {
    if(path === "/my-account/autorenew" && props.user.suspensionstatus === "ALL"){
      store.dispatch({ type: 'TOGGLE_SUSPEND_MODAL', payload: true });
    }
    else{
      props.history.push({ pathname: path, state: flag, autoRenewPage: true });
    }
  };

  const renderDescription = (description) => {
    const parts = description.split(/HREF#Here#/);
    let autoRenewFlag = parts[0].includes('Opt back') ? 1 : 0

    if((membershipdata.autorenewal == 0 && ( parts[0].includes('automatically') ||  parts[0].includes('Opt out/Cancel')))){
      return  ''
    }
    else if((membershipdata.autorenewal == 1 && ( parts[0].includes('Opt back')))){
      return  ''
    }
    else{
      return parts.map((part, index) => {
        if (index === 1) {
          const url = parts[index].split('#')[0];
          return (
             <span style={{ textDecoration: 'underline', cursor: 'pointer' }} className="linktag" key={index} href="#" onClick={(e) => { e.preventDefault(); handleNavigation(url, autoRenewFlag); }}>
             Here
            </span>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      });
    }
  };

  const disableContent = (data)=> {
    let autoRenewData =  moment(membershipdata.enddate).format("MMMM DD, YYYY");
    let replacevalue = data.replace("AUTORENEWDATE", autoRenewData);
    return replacevalue
  }
  const changetolink = (data) => {

    if (data.includes('HREF#Here#')) {
      const parts = data.split(/HREF#Here#/);
      return parts.map((part, index) => {
        if (index === 1) {
          const url = part.split('#')[0];
          return (
            <span
              key={index}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                handleNavigation(url);
              }}
            >
              Here
            </span>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      });
    } else if (data.includes('EMAILREF#Premier@CTBids.com#')) {
      const parts = data.split(/EMAILREF#/);
      return parts.map((part, index) => {
        if (index === 1) {
          const email = part.split('#')[0];
          return (
            <a className="mail-link" key={index} href={`mailto:${email}`} >
              {email}
            </a>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      });
      
    }else if (data.includes('TELEREF#')) {
      const parts = data.split('TELEREF#')
      return parts.map((part, index) => {
        if (index === 1) {
          const phone = part.split('#')[0];
          return (
            <a className="mail-link" key={index} href={`tel:${phone}`} >
              {phone}
            </a>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      });
    }
     else {
      return data;
    }
  };

  return (
    <>
       <Helmet>
        <title>CTBids | Premier Account</title>
      </Helmet>
      {
        loading ?
          <div className="h-100 d-flex align-items-center justify-content-center">
            <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
          </div> : errorMessage ? 
          <div className="d-flex align-items-center justify-content-center font-weight-bold h-100 display-5">{errorMessage}</div> : 
          <div className="memberservice" style={{ padding: "30px", overflow: "auto" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {myPrimerAccount?.logo ? <img src={myPrimerAccount.logo} style={{ width: "80px" }} alt="test" /> : ''}
              {myPrimerAccount?.title ? <div style={{ marginLeft: "10px", fontSize: "30px", fontWeight: "bold" }}>
                <h1 style={{ fontWeight: "bold" }}>{myPrimerAccount.title}</h1>
              </div> : ''}
            </div>

            <div className="main-content">
              {myPrimerAccount?.body.map((data, index) => (

                <div key={index} style={{ display: "flex", flexDirection: "column", margin: "10px 0" }}>
                  <div className="p-1" style={{ border: "1px solid white", backgroundColor: "white", padding: "10px" }}>
                    <div className="mt-3" style={{ fontWeight: "bold", color: '#A50050', fontSize: '15px' }}>{renderDescription(data.title)}</div>

                    {Array.isArray(data.description) && data.description.map((description, idx) => (
                      <p key={idx} className="mt-3">{renderDescription(description)}</p>
                    ))}

                    {typeof (data.description) === "string" && <p className="mt-3">{data.description}</p>}
                    {data?.expiredSubscription && membershipdata.autorenewal == 0 && <p className="mt-3">{ disableContent(data.expiredSubscription)}</p>}

                    {data.colorAndText && (
                      <div className="mt-3" style={{ display: "flex", flexWrap: "wrap" }}>
                        {data.colorAndText.map((data, idx) => (
                          <div key={idx} style={{ display: "flex", alignItems: "center", margin: "5px 0", width: "100%" }}>
                            <div style={{ width: "20px", height: "20px", backgroundColor: data.color, marginRight: "10px" }}></div>
                            <div className="text-bold">{changetolink(data.title)}</div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>}
    </>

  );
};

class MyPrimerAccount extends Component {
  constructor(props) {
    super(props);
    this.buyrId = this.props.user.id
  }

  componentDidMount() {
    store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
    ToggleSideBar();
    window.addEventListener('resize', ToggleSideBar);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', ToggleSideBar);
    removeSideBar();
  }

  render() {
    return <MyPrimerAccountfn {...this.props} />
  }
}

export default connect(state => state)(MyPrimerAccount);
