import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import store from "features/store";
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from "features/components/base/auth-pages/MyAccount/MyAccountMenuList";
import pmservice from "shared/services/pmservice";
import { logout } from "shared/settings/auth";
import './MyPrimerAccount.scss'
import { Button } from "react-bootstrap";
import moment from "moment";

const ThankYoufn = (props) => {
  const [memberServiceData, setMemberServiceData] = useState('')
  const [pageloading,  setPageLoading] = useState(true)

  useEffect(() => {
    const getMemberService = () => {
      pmservice.getContent('membershipthankyou', '').then((response) => {
        let memberServiceList = response.data.data[0].jsoncontent;
         memberServiceList = JSON.parse(memberServiceList);
         setMemberServiceData(memberServiceList);
         setPageLoading(false)
      })
      .catch((error) => {
        if (error?.status === 401) {
          store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
          logout(props.history, false);
        }
        setPageLoading(false)
      });
    }

    getMemberService();
  }, [])

  const addNavigation = (data) => {
    let subitledata = data.split('#StartBidding#')
    return subitledata.map((data, index)=>{
      if(index ==  1){
        return <span style={{ textDecoration: 'underline' }} onClick={()=>props.history.push('/estate-sale/list')}>{data}</span>
      }
      else{
        return <span key={index}>{data}</span>
      }
    })
   
  }

  const addEmailLink = (data) => {
    let email = data.split('#email#')
    if (email.length == 2) {
      return email.map((data, index) => {
        if (index == 1) {
          let emailvalue = data.split('#');
          return <a  className="clickable" style={{ color: '#00263E' }} key={index} href={`mailto:${emailvalue[0]}`}>{emailvalue[1]}</a>
        }
        else {
          return data
        }
      })
    } else {
      return data
    }
  }

  const navigateToScreen = (data) => {
    if(data.action.title == "Begin Return" || data.action.title == "File Delivery Claim") {
      window.open(data.action.navigatesToPage);
    } else {
      props.history.push(data.action.navigatesToPage);
    }
  }

  return (
    <>
      {pageloading ?
        <div className="h-100 d-flex align-items-center justify-content-center">
          <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
        </div> :
    <div className="memberserivce" style={{ padding: "30px", overflow: "auto" }}>
   <div className="d-flex align-items-center">
        <img className="w-80" src={memberServiceData?.logo}  />
        <div className="font-weight-bold fs-30" style={{ marginLeft: "10px" }}>
          <p className="font-weight-bold fs-30">{memberServiceData?.title}</p>
          {/* <p className="text-secondary">{addNavigation(memberServiceData?.subTitle)}</p> */}
        </div>
      </div>

      <div className="d-flex justify-content-between flex-wrap worthpoint-content mobile-resp">
        {memberServiceData?.body?.map((data, index) => (
          <div className="d-flex flex-column mt-5 mobile-wid" key={index} style={{ margin: "10px 0", width: 'calc(50% - 20px)' }}>
            <div>
              <img className="service-img" src={data.logo} alt={data.title} />
            </div>
            <div className="font-weight-bold fs-20 mt-2">{data.title}</div>
            <div>{addEmailLink(data.description)}</div>
            {data.action && data.action.type == "button" ?
              <Button className="service-btn" style={{backgroundColor: "#1D8296"}} onClick={() => { navigateToScreen(data) }}>
                {data.action.title}
              </Button> : ''}
          </div>
        ))}
      </div>
      <div className="mt-5">
        <div className="fs-20 font-weight-bold" style={{ color: '#A50050' }}>
          {memberServiceData?.footer?.heading}
        </div>
        <div className="d-flex align-items-center mt-4 mobile-foot-resp">
          <div className="float-left" style={{ width: '140px' }}>
            <img className="w-100" src={memberServiceData?.footer?.logo} alt={memberServiceData?.footer?.title} />
          </div>
          <div className="float-right ml-50">
            <div className="font-weight-bold fs-20">{memberServiceData?.footer?.title}</div>
            <div>{memberServiceData?.footer?.description}</div>
          </div>
        </div>
      </div>
    </div>}
    </>
  );
};

class membershipThankYou extends Component {
  constructor(props) {
    super(props);
    this.buyrId = this.props.user.id
  }

  componentDidMount() {
    store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
    ToggleSideBar();
    window.addEventListener('resize', ToggleSideBar);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', ToggleSideBar);
    removeSideBar();
  }

  render() {
    return <ThankYoufn {...this.props} />
  }
}

export default connect(state => state)(membershipThankYou);
