import moment from "moment";
import React, { useEffect, useState, Component } from "react";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";
import { bidBIcon, new_image_thumbnail, sneakpreview, timetable } from "shared/images/images";
import { ItemsService, SalesService } from "shared/services";
import { isAuthorized, logout } from "shared/settings/auth";
import store from 'features/store';
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from "../base/auth-pages/MyAccount/MyAccountMenuList";
import './auctionsp.scss';
import { addDataLayer } from "shared/utils/utils";
import { Helmet } from "react-helmet";

function AuctionSneakPeekfn(props) {
    const naming = {
        "newlisting": "New Listing Preview",
        "bidderinterest": "Chosen For You",
        "uniqueitems": "Unique Treasure"
    }
    const [pageLoading, setPageLoading] = useState(true);
    const [saleList, setSaleList] = useState([]);
    const [istodaySale, setistodaySale] = useState(false);
    const [itemList, setitemList] = useState([]);
    const [nextList, setNextList] = useState(null);
    const [loaderForMore, setLoaderForMore] = useState(false);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [disableWatch, setDisableWatch] = useState(false);

    useEffect(() => {
        loading();
        const handleResize = () => {
            setInnerWidth(window.innerWidth);
          };
      
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
    }, []);

    useEffect(() => {
        let dataLayerData = {
            'event': 'auction_sneak_peek',
            'buyr_id': props.user.id
        }
        addDataLayer(dataLayerData);
    }, []);

    const loading = async () => {
        await getItemList();
        await getSaleList();
        setPageLoading(false);
    }
 
    const getItemList = async () => {
        ItemsService.getPreviewItemsList().then(
            async (itemList) => {
                const temp = itemList.data.data;
                let data = Object.keys(temp)
                data = data.map((item) => {
                    return ({
                        ctype: item,
                        ...temp[item]
                    })
                })
                setitemList(data)
                await getWatchList(data)
                
            }
        ).catch((error) => {
            if (error?.status === 401) {
                store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
                logout(props.history, false);
            }
        });
    }
    
    const getWatchList = async (itemLists) => {
        if (isAuthorized()) {
            let itemId = [];
            itemLists.forEach(item => {
                itemId.push(item.item_id);
            });
            if (itemLists.length > 0) {
                const data = {
                    "sort": [{ "field": "id", "direction": "desc" }],
                    "filter": [{ "field": "itemid", "value": [itemId], "op": "IN", "join": "AND" }],
                    "field": ["itemid"],
                    "page": { "size": itemLists.length + 1 }
                };
                await ItemsService.getWatchListItem(data).then(
                    response => {
                        const watch = response.data.data;
                            itemLists = itemLists.map((item) => {
                                return ({
                                    ...item,
                                    iswatch: watch.filter(i => i?.itemid === item?.item_id).length > 0
                                })
                            })
                            setitemList(itemLists)
                    }
                ).catch(error => {
                });
            }
        } else {
        }
    }
    const getSaleList = async (append = false) => {
        let newDate = moment().subtract(7, 'days').format("YYYY-MM-DD 00:00:00");
        let endDate = moment().add(7, 'days').format("YYYY-MM-DD 23:59:59");
        const data = {
            "page": nextList && append ? { "size": 5, next: nextList } : { 'size': 5 },
            "sort": [{ "field": "starttime", "direction": "desc" }],
            "field": ["id", "title", "city", "state", "zipcode", "starttime", "endtime", "itemcount", "client", "contactname", "locationid", "locationtitle", "status", "modifieddate", "provenance", "timezoneid", "timezonename", "endtimeutc"],
            "filter": [
                { "field": "starttime", "value": newDate, "op": ">=", "join": "AND" },
                { "field": "starttime", "value": endDate, "op": "<=", "join": "AND" },
            ]
        }
        await SalesService.getSneakPeekSaleList(data).then(
            async (resp) => {
                let saleListData = resp?.data?.data
                let saleId = saleListData.map(elem => { return elem.id })
                let payload = { "filter": [{ "field": "saleid", "value": [saleId], "op": "IN", "join": "AND" }] };
                const saleImageApiResult = await SalesService.getSaleImagesList(payload)
                if (saleImageApiResult?.data.status?.toLowerCase() == "success") {
                    let saleImageListData = saleImageApiResult?.data?.data;

                    await saleListData.forEach(sale => {
                        let url = null;
                        let data = saleImageListData.find(elem => { return elem.saleid == sale.id });
                        if (data) {
                            let urlArr = typeof data?.saleimageurl === 'string' ? data.saleimageurl.split(',') : typeof data?.locationimageurl === 'string' ? data.locationimageurl.split(',') : [];
                            let profileUrl = urlArr.find(elem => { return elem.includes('profile') });
                            url = typeof profileUrl == 'string' && profileUrl.includes('#*#') ? profileUrl.split("#*#")[1] : new_image_thumbnail;
                        }
                        sale['saleimageurl'] = url;
                    });
                }
                if (saleListData.length > 0) {
                    let moreDataAppend = append ? saleList : [];
                    moreDataAppend = moreDataAppend.concat(saleListData);
                    setSaleList(moreDataAppend);
                    setistodaySale(false);
                    setNextList(resp.data.page && resp.data.page.keyset && resp.data.page.keyset.next ? resp.data.page.keyset.next : null);
                    setLoaderForMore(false);
                } else {
                    setistodaySale(true);
                }
            }
        ).catch((error) => {
            if (error?.status === 401) {
                store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
                logout(props.history, false);
            }
            setLoaderForMore(false);
        });
    }

    const watching = (item, index) => {
        setDisableWatch(true);
        if (!isAuthorized()) {
            return
        }

        if (item?.iswatch) {
            const removeData = {
                "data": {
                    "itemIds": [item?.item_id]
                }
            };
            ItemsService.removeFromWatch(removeData).then(
                async(response) => {
                    // let ilist = [...itemList]
                    // ilist[index]["iswatch"] = false
                    // setitemList(ilist)
                    await getWatchList(itemList);
                    setDisableWatch(false);
                }
            ).catch(error => {
            });
        } else {
            const addData = {
                "data": [
                    {
                        "itemid": item?.item_id
                    }
                ]
            }
            ItemsService.addToWatch(addData).then(
                async(response) => {
                    // let ilist = [...itemList]
                    // ilist[index]["iswatch"] = true
                    // setitemList(ilist)
                    await getWatchList(itemList);
                    setDisableWatch(false);
                }
            ).catch(error => {
            });
        }

    }

    const imageNavigateToItem = (item) => {
        props.history.push({
            pathname: `/my-account/premier/auction-sneak-peak-item/${item.sale_id}/item/${item?.item_id}`, state: {
                saleId: item.sale_id,
                sale: {
                    id: item.sale_id,
                    locationid: item.loc_id
                },
                item: {
                    id: item.item_id
                }
            }
        });
    }

    const getMoreList = () => {
        setLoaderForMore(true);
        getSaleList(true);
    }

    if (pageLoading)
        return (
            <div className="h-100 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
            </div>)
    return (
        <>
            <Helmet>
                <title>CTBids | Premier Auction Sneak Peek</title>
            </Helmet>
          {pageLoading ?
          <div className="h-100 d-flex align-items-center justify-content-center">
            <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
          </div> :
        
        <div className="asp">
            {props.user.suspensionstatus === "ALL" ?
                <div className="w-100" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center', width: '60%' }}>
                    You are currently suspended from bidding on CTBids. Please reach out to <a  style={{ color: '#A50050' }} href="tel:1-833-287-7364" >1-833-287-7364</a> or email <a style={{ color: '#A50050' }} href="mailto:Premier@CTBids.com">Premier@CTBids.com</a> if you have any questions.
                    </div>
                </div> 
                : <div className="asps_container w-100" style={{ overflowY: 'scroll', height: '100vh' }}>
                <div className="asp_container">
                    <div className="header">
                        <div className="center">
                            <img className="timetable" src={timetable} />
                            <div className="title">
                                <h1 className="font-weight-bold ">Auction Sneak Peek</h1>
                                <div className="head_cont">
                                    <h6 style={{  fontSize: '20px' }}>You are amongst the first to preview upcoming CTBids auctions.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="itemlist">
                            {itemList?.map((item, index) => (
                                <div key={`items_${index}`} className="sp-card d-flex flex-column align-items-center justify-content-center clickable">
                                    <div className="text-center">
                                        <h4 className="text-navy no-wrap font-weight-bold">{naming[item?.ctype]}</h4>
                                    </div>
                                    <div className={"item_card"}>
                                        <div className="image-card clickable" role={"button"} onClick={() => {imageNavigateToItem(item)}}>
                                            <div style={{height: '250px'}}>
                                                <img src={item.image_url ? item.image_url : item.thumbnail_url ? item.thumbnail_url : new_image_thumbnail} alt="item"></img>
                                            </div>

                                            {/* {
                                            item.image_url || item.displayimage ?
                                                <img className="item-image " src={item.image_url ? item.image_url : item.image_url ? item.image_url : new_image_thumbnail} alt="item"></img>
                                                : <div className="d-flex flex-column align-items-center justify-content-center">
                                                    <img alt="item-image" src={new_image_thumbnail} className="item-image"></img>
                                                </div>
                                        } */}
                                        </div>
                                        <div className="item_text">
                                            <div className="item-card-title">{item?.item_title}</div>
                                            <div className={`clickable ${item?.iswatch ? "sp-watching" : "watch"}`}>
                                                <button onClick={() => { watching(item, index) }} disabled={disableWatch}>
                                                    <div style={{ color: "#FFF" }}>Watch</div>
                                                    <i className=" fa fa-eye" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        {istodaySale && <h5>Currently, we are crafting new and unique estate sales. <br></br>
                            As soon as a new auction is available to preview it will be listed here.<br />
                            With over 500 auctions each month you won't have to wait long</h5>}
                    </div>
                </div>
                <div className="p-40">
                    <div className="header">
                        {istodaySale ? <div className="left">
                            <h1>Live Auctions You Can Bid On Today</h1>
                        </div> : 
                        <div className="left">
                            <h1 className="no-wrap">Auctions Coming Soon</h1>
                        </div>}
                        {innerWidth >= '610' ?
                            <div className="right d-flex align-items-center justify-content-center">
                                {
                                    !istodaySale ? <h4 className="no-wrap font-weight-bold">Tentative Start Date</h4> : ''
                                }
                            </div>
                            : ''}
                    </div>
                    <div className="header">
                        <div className="left">
                            <h6 className="mt-3 mb-auction-4">We know you're excited about upcoming auctions, we are too. Until this auction is live, we will not be able to answer any questions or provide additional information regarding these items.
                                Auction dates and items are subject to change. Click on the auction listing below to preview their items.
                            </h6>
                        </div>
                        {innerWidth >= '610' ?
                            <div className="right"></div> : ''
                        }
                    </div>
                    <div style={{ height: "100%" }}>
                        {saleList?.map((item, index) => (
                            <div className="content sale-content"
                                role={"button"}
                                onClick={() => {
                                    props.history.push({
                                        pathname: `/my-account/premier/auction-sneak-peak-sale/${item?.id}`, state: {
                                            sale: { ...item },
                                        }
                                    });
                                }}>
                                <div className="c_left">
                                    <div className="image sale-image-border">
                                        <img key={`sale_image`} className="sale-image" src={item?.saleimageurl ? item?.saleimageurl : new_image_thumbnail} alt="item - image"></img>
                                    </div>
                                    <div className="d-flex flex-column p-3">
                                        <h5 style={{ fontWeight: "none", padding: "10px 0px" }}>{item?.title}</h5>
                                        <h6 className="text-bold">Location : {item?.locationtitle}</h6>
                                    </div>
                                </div>
                                {
                                    innerWidth <= '610' ?
                                        <div className="c_right">
                                            {!istodaySale &&
                                                <div className="d-flex align-items-center justify-content-center w-100 pd-bt">
                                                    {
                                                        !istodaySale &&
                                                        <div className="text-navy no-wrap">
                                                            <span className="font-weight-bold">Tentative Start Date: </span> {moment(item?.starttime).format("MMMM DD YYYY")}
                                                        </div>
                                                    }
                                                    <div className="w-20">
                                                        {moment(item?.starttime) <= moment(new Date()) && item.status == 'Started' ?
                                                            <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip-clone" style={{ visibility: 'visible' }}>This sale is live</Tooltip>}>
                                                                <div><img src={bidBIcon} alt="bid"></img></div>
                                                            </OverlayTrigger>
                                                            : null}
                                                    </div>
                                                </div>
                                            }
                                        </div> :
                                        <div className="c_right">
                                            {!istodaySale &&
                                                <div className="d-flex align-items-center justify-content-center w-100 pd-bt">
                                                    {
                                                        !istodaySale &&
                                                        <div className="text-navy no-wrap">
                                                            {moment(item?.starttime).format("MMMM DD YYYY")}
                                                        </div>
                                                    }
                                                    <div className="w-20">
                                                        {moment(item?.starttime) <= moment(new Date()) && item.status == 'Started' ?
                                                            <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip-clone" style={{ visibility: 'visible' }}>This sale is live</Tooltip>}>
                                                                <div><img src={bidBIcon} alt="bid"></img></div>
                                                            </OverlayTrigger>
                                                            : null}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                }
                            </div>
                        ))}</div>
                </div>
                {
                    nextList ?
                        <div className="d-flex justify-content-center mt-5">
                            <div className={`browse-all clickable ${nextList ? '' : 'custom-disable'} ${loaderForMore ? 'custom-disable' : ''}`} onClick={getMoreList}>
                                More {loaderForMore ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
                            </div>
                        </div> : null
                }
                <div className="d-flex flex-row justify-content-center mrbt-2 mt-3">
                    <img className="logobid" src={sneakpreview} />
                </div>
            </div>}
        </div>}</>
    )
}
class AuctionSneakPeek extends Component {
    constructor(props) {
        super(props);
        this.buyrId = this.props.user.id
    }

    componentDidMount() {
        store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
        ToggleSideBar();
        window.addEventListener('resize', ToggleSideBar);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', ToggleSideBar);
        removeSideBar();
    }

    render() {
        return <AuctionSneakPeekfn {...this.props} />
    }
}

export default connect(state => state)(AuctionSneakPeek);