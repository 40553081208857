import React, { Component, useEffect, useState } from "react";
import { connect } from 'react-redux';
import store from 'features/store';
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from 'features/components/base/auth-pages/MyAccount/MyAccountMenuList';
import pmservice from "shared/services/pmservice";
import './alzheimersFoundation.scss'
import { isAuthorized, logout } from "shared/settings/auth";
import { addDataLayer } from "shared/utils/utils";
import { Helmet } from "react-helmet";


export const AlzheimersFoundation = (props) => {
  const [loading, setLoading] = useState(true)
  const [alzheimersFoundation, setAlzheimersFoundation] = React.useState([]);

  useEffect(() => {
    if(isAuthorized()) {
      if (props.user.id) {
        getAlzheimersFoundation();
      } else {
        store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
        logout(props.history, false);
      }
    } else {
      store.dispatch({ type: 'TOGGLE_SIGNIN_MODAL', payload: { open: true, mode: 'Sign-In' } });
    }
  }, [props.user.id]);

  useEffect(() => {
    let dataLayerData = {
      'event': 'alzheimer_foundation',
      'buyr_id': props.user.id
    }
    addDataLayer(dataLayerData);
  }, []);

  const getAlzheimersFoundation = () => {
    pmservice.getContent("AlzheimersFoundation", "").then((data) => {
      let alzheimersFoundationdata = data.data.data[0].jsoncontent;
      let parseData = JSON.parse(alzheimersFoundationdata);

      setAlzheimersFoundation(parseData[0]);
      setLoading(false)
    })
      .catch((error) => {
        if (error?.status === 401) {
          store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
          logout(props.history, false);
        }
        setLoading(false)
      });
  };

  const redirectToPage = (data) => {
    const parts = data.split(/HREF##/);

    return parts.map((part, index) => {
      if (index === 1) {
        const url = parts[index].split('#')[1];
        const name = parts[index].split('#')[0];
        return (
          <a style={{ textDecoration: 'underline', cursor: 'pointer' }} className="linktag" key={index} href={url} target="_blank">
            {name}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };

  return (
    <>
      <Helmet>
          <title>CTBids | Alzheimer’s Foundation</title>
      </Helmet>
      {loading ?
        <div className="h-100 d-flex align-items-center justify-content-center">
          <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
        </div> :
        <div className="AlzheimersFoundation" style={{ padding: '30px', overflow: 'auto' }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={alzheimersFoundation?.logo} style={{ width: '80px' }} />
            <div style={{ marginLeft: '10px', fontSize: '30px', fontWeight: 'bold' }}>
              <h1 style={{fontWeight: 'bold' }}>{alzheimersFoundation?.title}</h1>
              <div style={{ fontSize: '20px', fontWeight: '600', color: '#A50050' }}>{alzheimersFoundation?.subtitle}</div>
            </div>
          </div>

          <div className="mt-5 mb-3" style={{ width: '40%', marginLeft: "0.6rem" }} >
            <img src={alzheimersFoundation?.header?.logo} style={{ width: '200px', textAlign: 'center' }} />
          </div>

          <div className="worthpoint-content">
            {alzheimersFoundation?.body?.map((data, index) => (
              <div key={index} style={{ display: "flex" }}>
                <div className="p-1" style={{ width: "90%", border: '1px solid white', backgroundColor: 'white' }}>
                  <div className="mt-3" style={{ fontWeight: 'bold', color: '#A50050' }}>{data.title}</div>

                  {data.description.map((description, idx) => (
                    <p key={idx} className="mt-3">{redirectToPage(description)}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>}
    </>
  );
};
class alzheimersFoundation extends Component {
  constructor(props) {
    super(props);
    this.buyrId = this.props.user.id
  }

  componentDidMount() {
    store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
    ToggleSideBar();
    window.addEventListener('resize', ToggleSideBar);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', ToggleSideBar);
    removeSideBar();
  }

  render() {
    return <AlzheimersFoundation {...this.props} />
  }
}

export default connect(state => state)(alzheimersFoundation);
