import { Component, useEffect, useState } from "react";
import { connect } from "react-redux"
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from "../MyAccountMenuList";

import './VipMember.scss';
import store from "features/store";
import authService from "shared/services/authService";
import { Button } from "react-bootstrap";
import { isAuthorized, logout } from "shared/settings/auth";
import pmservice from "shared/services/pmservice";
import { bannerImage } from "shared/utils/utils";
import { Helmet } from "react-helmet";
import { HeaderHOC } from "../headerHOC/HeaderHOC";

export const VipMemberFn = (props) => {

    const [pageLoading, setPageLoading] = useState(true);
    const [vipMemberData, setVipMemberData] = useState([])
    const [profileImage, setProfileImage] = useState({
        modal: false,
        imgSrc: null,
        tempImg: null
    })

    useEffect(() => {
        if(isAuthorized()) {
            if (props.user.membershipCompleted) {
                props.history.push("/");
            } else {
                if (props.user.id && !props.user.membershipCompleted) {
                    getProfileData();
                    getVipMemberdata();
                } else {
                    store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
                    logout(props.history, false);
                }
            }
        } else {
            store.dispatch({ type: 'TOGGLE_SIGNIN_MODAL', payload: { open: true, mode: 'Sign-In' } });
        }
    }, [props.user.id]);

    const getVipMemberdata = () => {
        pmservice.getContent('PremierMember',"").then((res)=>{
            let data = res.data.data[0];
            let jsonContent = JSON.parse(data.jsoncontent)
            setVipMemberData(jsonContent)
            setPageLoading(false)
        }).catch((error)=>{
            if (error?.status === 401) {
                store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
                logout(props.history, false);
              }
            setPageLoading(false)
        })
    }
    const handleNavigation = (path) => {
        props.history.push({ pathname: path});
      };
    const linkAddFn = (data) => {
        const parts = data.split(/SPLIT#/);

        return parts.map((part, index) => {
            if (index === 1) {
                const termsAndCondtion = parts[index].split('#');
                return (
                    <a style={{color:'blue', textDecoration: 'underline', cursor: 'pointer' }} target="_blank" className="terms-link" key={index} href={termsAndCondtion[1]}>
                        {termsAndCondtion[0]}</a>  
                );
            }
            else if (index === 3) {
                const linkdata = parts[index].split('#');
                return (
                    <span style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}  onClick={()=> {handleNavigation(linkdata[0])}}>
                        {linkdata[1]}</span>
                );
            }
            else {
                return <span key={index}>{part}</span>;
            }
        });

    }

    const getProfileData = () => {
        authService.getUserDetails(props.user.id).then(response => {
            const profileData = { ...response.data.data[0] };
            setProfileImage({
                ...profileImage,
                imgSrc: profileData.url
            })
        }).catch(()=>{
            setPageLoading(false)
        })
    }

    const addUserNameFn = (data) => {
        let title = data
        return title = data.replace("NAMEPLACEHOLDER", props.user.firstName)
    }

    const textColorChange = (data) => {
        if (data.includes('#')) {
            let contentData = data.split('#');
            return (
                <>
                    {contentData[0] && <span style={{ color: '#A50050' }}>{contentData[0]}</span>}
                    {contentData[1] && contentData[1]}
                </>
            );
        } else {
            return data;
        }
    };

    return (
        <>
            <Helmet>
                <title>CTBids | Become a Premier Member</title>
            </Helmet>
        {pageLoading ?
            <div className="h-100 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
            </div> :
            <div style={{ overflow: 'auto' }}>
                <div className="vip-member p-3 w-100">
                    <HeaderHOC { ...props}/>
                    <div className="d-flex profile-background profile-image align-items-center justify-content-sm-start" style={{ gap: "20px", marginLeft: '20px'}}>
                    <div className="d-flex justify-content-start align-items-end profile-background profile-image">
                            <div className="d-flex flex-row align-items-center justify-content-center profile-rounded">
                                { profileImage.imgSrc ?
                                    <img src={profileImage.imgSrc} alt="profile-image" style={{ height: '95px', width: '135px' }} /> :
                                    <img src={`${process.env.PUBLIC_URL}/static/icons/profile/default_2.svg`} alt="default" />
                                }
                            </div>
                        </div>
                        <div>
                           {vipMemberData?.title ?  <div className="f-16 font-weight-bold">{addUserNameFn(vipMemberData?.title)}</div> : ''}
                        </div>
                    </div>
                </div>
                <div style={{ padding: '0 30px'}}>
                    {!props.user.membershipCompleted ?
                        <div style={{ margin: "0px" }}>
                            {bannerImage(props)}
                        </div> : ''}
                    <div className="d-flex" >
                        {vipMemberData.header && vipMemberData.header[1].colorLines.map((color, index) => (
                            <div key={index} style={{ backgroundColor: color, padding: '5px', width: '17.6%' }}></div>
                        ))}
                    </div>
                </div>       

                <div className="heading-section">
                <h3 className="heading-content">{vipMemberData.body && vipMemberData.body[0].description}</h3>
                <div className="d-flex justify-content-between flex-wrap worthpoint-content mobile-resp">
                        {vipMemberData.body && vipMemberData.body[0].benefitData.map((data, index) => (
                            <div className="d-flex flex-column mt-5 mobile-wid" key={index} style={{ margin: "10px 0", width: 'calc(50% - 20px)' }}>
                                <img style={{ width: "80px" }} src={data.logo} alt="benefit-logo" />
                                <div className="font-weight-bold mt-3">{data.title}</div>
                                <div>{data.description}</div>
                         
                            </div>
                        ))}
                    </div>
                </div>

                <div style={{ padding: '0 30px'}}>
                    {!props.user.membershipCompleted ?
                        <div style={{ margin: "0px" }}>
                            {bannerImage(props)}
                        </div> : ''}
                    <div className="d-flex" >
                        {vipMemberData.footer && vipMemberData.footer[1].colorLines.map((color, index) => (
                            <div key={index} style={{ backgroundColor: color, padding: '5px', width: '17.6%' }}></div>
                        ))}
                    </div>
                </div>

                <div className="d-flex align-items-center  p-2 w-100 mt-4 mb-5" style={{ width: '100%' }}>
                    <div style={{ padding: "0 0 0 2rem" }} >
                        <img className="afa-img" src={vipMemberData.footer && vipMemberData.footer[2].logo}  />
                    </div>
                    <div style={{ width: '90%', marginLeft: "1.1rem" }}>
                        <div style={{ fontWeight: 'bold' }}>{vipMemberData.footer && vipMemberData.footer[2].title}</div>
                        <div>{vipMemberData.footer && vipMemberData.footer[2].subtitle}</div>
                    </div>
                </div>
                    <div>
                        {vipMemberData?.benefitsDetails && (
                            <>
                                <h1 className="text-center benefits-heading  font-weight-bold mt-3" >{vipMemberData?.benefitsDetails.title}</h1>
                            </>
                        )}

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '0% 5%' }}>
                            {vipMemberData?.benefitsDetails &&
                                vipMemberData.benefitsDetails.benefitsContent.map((section, index) => (
                                    <div key={index} style={{
                                        display: 'flex',
                                        flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                                        alignItems: section.title !== 'WorthPoint Subscription' ? 'center' : '',
                                        marginTop: '3rem',
                                        justifyContent: 'space-between'

                                    }} >
                                        <div style={{ flex: '0 0 auto',
                                                marginTop: section.title == 'WorthPoint Subscription' ? '1rem' : '',
                                                textAlign: index % 2 === 0 ? 'left' : 'right' }}>
                                            <img src={section.logo} alt="logo" style={{ width: '100px' }} />
                                        </div>

                                        <div style={{ flex: '1', margin: '0 1rem' }}>
                                            <div className="font-weight-bold" style={{ color: section.titleColor, fontSize: 'large' }}>{section.title}</div>
                                            {Array.isArray(section.description) ? (
                                                section.description.map((desc, idx) => (
                                                    <p className="mt-3" key={idx}>{desc}</p>
                                                ))
                                            ) : (
                                                <p>{textColorChange(section.description)}</p>
                                            )}

                                            {section.descriptionPoints &&
                                                section.descriptionPoints.map((point, idx) => (
                                                    <div key={idx} className="mt-3">
                                                        <ul>
                                                            <li>{point}</li>
                                                        </ul>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <div style={{ padding: '0 30px'}}>
                    {!props.user.membershipCompleted ?
                        <div style={{ margin: "0px" }}>
                            {bannerImage(props)}
                        </div> : ''}
                    <div className="d-flex" >
                        {vipMemberData.header && vipMemberData.header[1].colorLines.map((color, index) => (
                            <div key={index} style={{ backgroundColor: color, padding: '5px', width: '17.6%' }}></div>
                        ))}
                    </div>
                </div>  
                    </div>
                    <div className="mt-5">
                    <div  style={{ padding: '0% 10% 0% 10%', marginBottom: '20px', marginTop: '180px' }}>
                        {vipMemberData.footer && linkAddFn(vipMemberData.footer[2].description)}
                    </div>
                </div>
            </div>}
    </>
);
}

class VipMember extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
        ToggleSideBar();
        window.addEventListener('resize', ToggleSideBar);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', ToggleSideBar);
        removeSideBar();
    }

    render() {
        return <VipMemberFn {...this.props} />
    }
}

export default connect(state => state)(VipMember);