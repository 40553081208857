import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import store from "features/store";
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from "features/components/base/auth-pages/MyAccount/MyAccountMenuList";
import { Button } from "react-bootstrap";
import './MemberService.scss'
import pmservice from "shared/services/pmservice";
import { isAuthorized, logout } from "shared/settings/auth";
import { addDataLayer } from "shared/utils/utils";
import { Helmet } from "react-helmet";

const MemberServicefn = (props) => {
  

  const [memberServiceData, setMemberServiceData] = useState('')
  const [pageloading,  setPageLoading] = useState(true)

  useEffect(() => {
    if(isAuthorized()) {
      if (props.user.id) {
        getMemberService();
      } else {
        store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
        logout(props.history, false);
      }
    } else {
      store.dispatch({ type: 'TOGGLE_SIGNIN_MODAL', payload: { open: true, mode: 'Sign-In' } });
    }
  }, [props.user.id]);

  useEffect(() => {
    let dataLayerData = {
      'event': 'member_service',
      'buyr_id': props.user.id
    }
    addDataLayer(dataLayerData);
  }, []);

  const getMemberService = () => {
    pmservice.getContent('YourPremierBenefits', '').then((response) => {
      let memberServiceList = response.data.data[0].jsoncontent;
       memberServiceList = JSON.parse(memberServiceList);
       setMemberServiceData(memberServiceList);
       setPageLoading(false)
    })
    .catch((error) => {
      if (error?.status === 401) {
        store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
        logout(props.history, false);
      }
      setPageLoading(false)
    });
  }

  const navigateToScreen = (data) => {
    if(data.action.title == "Begin Return" || data.action.title == "File Delivery Claim") {
      window.open(data.action.navigatesToPage);
    } else {
      props.history.push(data.action.navigatesToPage);
    }
  }
  const addEmailLink = (data) => {
    let email = data.split('#email#')
    if (email.length == 2) {
      return email.map((data, index) => {
        if (index == 1) {
          let emailvalue = data.split('#');
          return <a  className="clickable" style={{ color: '#00263E' }} key={index} href={`mailto:${emailvalue[0]}`}>{emailvalue[1]}</a>
        }
        else {
          return data
        }
      })
    } else {
      return data
    }
  }
  
  return (
    <>
      <Helmet>
        <title>{`${props.history.location.pathname}` === '/my-account/premier/premier-membership' ? `CTBids | Premier Membership` : `CTBids | Member Benefits`}</title>
      </Helmet>
      {pageloading ?
        <div className="h-100 d-flex align-items-center justify-content-center">
          <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
        </div> :
    <div className="memberserivce" style={{ padding: "30px", overflow: "auto" }}>
   <div className="d-flex align-items-center">
        <img className="w-80" src={memberServiceData?.logo}  />
        <div className="font-weight-bold" style={{ marginLeft: "10px" }}>
          <h1 className="font-weight-bold">{memberServiceData?.title}</h1>
        </div>
      </div>

      <div className="d-flex justify-content-between flex-wrap worthpoint-content mobile-resp">
        {memberServiceData?.body?.map((data, index) => (
          <div className="d-flex flex-column mt-5 mobile-wid" key={index} style={{ margin: "10px 0", width: 'calc(50% - 20px)' }}>
            <img className="service-img" src={data.logo} alt={data.title} />
            <div className="font-weight-bold fs-20 mt-2">{data.title}</div>
            <div>{addEmailLink(data.description)}</div>
            {data.action && data.action.type == "button" ?
              <Button className="service-btn" style={{backgroundColor: "#1D8296"}} onClick={() => { navigateToScreen(data) }}>
                {data.action.title}
              </Button> : ''}
          </div>
        ))}
      </div>
      <div className="mt-5 w-100">
        <div className="fs-20 font-weight-bold" style={{ color: '#A50050' }}>
          {memberServiceData?.footer?.heading}
        </div>
        <div className="d-flex align-items-center mt-4 mobile-foot-resp">
          <div className="float-left" style={{ width: '140px' }}>
            <img className="w-100 clickable" onClick={()=>window.open(memberServiceData?.footer?.navigatesTo)} src={memberServiceData?.footer?.logo} alt={memberServiceData?.footer?.title} />
          </div>
          <div className="float-right ml-50 w-100">
            <div className="font-weight-bold fs-20">{memberServiceData?.footer?.title}</div>
            <div className="w-100 footer-resp">{memberServiceData?.footer?.description}</div>
          </div>
        </div>
      </div>
    </div>}
    </>
  );
};

class MemberService extends React.Component {
  constructor(props) {
    super(props);
    this.buyrId = this.props.user.id;
  }

  componentDidMount() {
    store.dispatch({ type: "SIDEBAR_MENU_LIST", payload: { menuList: MyAccountMenuList(), withSpan: false } });
    ToggleSideBar();
    window.addEventListener("resize", ToggleSideBar);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", ToggleSideBar);
    removeSideBar();
  }

  render() {
    return <MemberServicefn {...this.props} />;
  }
}

export default connect((state) => state)(MemberService);
