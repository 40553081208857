import { Component, useEffect, useState } from "react";
import { connect } from "react-redux"
import { Form, Button,Spinner } from 'react-bootstrap';
import { CategoryService } from "shared/services";
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from "../MyAccountMenuList";
import { getBuyersUrl } from "shared/http";
import Axios from 'axios';
import { membershipCategoryAdd, membershipCategoryList, membershipCategoryUpdate } from "shared/settings/apiconfig";
import { getBuyerHeaders } from "shared/http";

import './PersonalInterest.scss';
import store from "features/store";
import { logout, setUser } from "shared/settings/auth";
import { pmAutoNavigateToNextScreen } from "shared/utils/utils";
// import { HeaderHOC } from "../headerHOC/HeaderHOC";
import '../personal-info/PersonalInfo.scss';

import authService from "shared/services/authService";
const closestData = ["Bidding For Yourself", "Collecting Treasures", "Gift Giving", "Reselling"];

export const PersonalInterestFn = (props) => {    
    const [pageLoading, setPageLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [interestedCategory, setInterestedCategory] = useState([]);
    const [validMessage, setValidMessage] = useState('');
    const [closestMatch, setClosestMatch] = useState(null);
    const [favouriteCategory, setFavouriteCategory] = useState(null);
    const [recordStatus, setRecordStatus] = useState(false);
    const [checkStatus, setCheckStatus] = useState(false);
    const [categoryid, setCategaryId] = useState('');
    const [profileImage, setProfileImage] = useState({
        modal: false,
        imgSrc: null,
        tempImg: null
    })
    const [previousProfile, setPreviousProfile] = useState({...props.user});

    useEffect(() => {
        getRequiredData();
    }, []);

    useEffect(() => {
        pmAutoNavigateToNextScreen(props, previousProfile, props.user, "interestCompleted");
    }, [props.user.interestCompleted])

    const getRequiredData = () => {
        getProfileData();
        setPageLoading(true);
        CategoryService.getCategorySelectionList().then(
            categorySelectionList => {
                const tempList = categorySelectionList.data.data.data;
                setCategoryList(tempList);
                getCategoryList()
               
            }
        ).catch(error => {
            setPageLoading(false);
        });
    }
    const getProfileData = () => {
        authService.getUserDetails(props.user.id).then(response => {
            const profileData = { ...response.data.data[0] };
            setProfileImage({
                ...profileImage,
                imgSrc: profileData.url
            })
        }).catch(()=>{
            setPageLoading(false)
        })
    }
    const getCategoryList = async () => {
        let data = {"field":["id", "buyrId","categorynames","favouritecategory", "preference"]}
        await Axios.post(getBuyersUrl(membershipCategoryList), JSON.stringify(data), getBuyerHeaders())
            .then(response => {
                setPageLoading(false);
                const categoryData = response.data.data[0];
                if (categoryData ) {
                    setRecordStatus(true);
                    setInterestedCategory(JSON.parse(categoryData.categorynames));
                    setFavouriteCategory(JSON.parse(categoryData.favouritecategory)[0]);
                    setClosestMatch(JSON.parse(categoryData.preference)[0]);
                    setCategaryId(categoryData.id)
                } else {
                    setRecordStatus(false);
                }
            }).catch(error => {
                if (error?.response?.status === 401) {
                    store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
                    logout(props.history, false);
                }
                setPageLoading(false);
            })
    }
    const updateCategory = () =>{
        setCheckStatus(true)

        let data = {
            "data": [
              {
                "id": categoryid,
                "buyrId": props.user.id,
                "categorynames": interestedCategory,
                "favouritecategory": [favouriteCategory],
                "preference": [closestMatch]
              }
            ]
          }
        Axios.put(getBuyersUrl(membershipCategoryUpdate), JSON.stringify(data ), getBuyerHeaders())
        .then(response => {
            setCheckStatus(false)
            setValidMessage("Saved")
            setTimeout(() => {
                setValidMessage("")
            }, 3000);
            setUser(props.user.id);
        }).catch(error => {
            setPageLoading(false);
        })
    }

    const handleInterestCheckboxChange = (event) => {
        const { name } = event.target;
        setInterestedCategory(prevState => {
            let array = [...prevState]; 
            if (array.includes(name)) {
                array.splice(array.indexOf(name), 1);
            } else {
                array.push(name);
            }

            return [...array];
        });
    }

    const handleFavouriteCheckboxChange = (event) => {
        const { name } = event.target;
        setFavouriteCategory((prevFavourite) => (prevFavourite === name ? null : name));
    };

    const handleClosestCheckboxChange = (event) => {
        const { name } = event.target;
        setClosestMatch((prevFavourite) => (prevFavourite === name ? null : name));
    }

    const navigateToPayment = () => {
        pmAutoNavigateToNextScreen(props, previousProfile, props.user, "interestCompleted", null, true);
    }
    
    const submitCategory = () => {
        setCheckStatus(true)
        let data = {
            "data": [
              {
                "buyrId": props.user.id,
                "categorynames": interestedCategory,
                "favouritecategory": [favouriteCategory],
                "preference": [closestMatch]
              }
            ]
          }
        Axios.post(getBuyersUrl(membershipCategoryAdd), JSON.stringify(data ), getBuyerHeaders())
        .then(response => {
            setValidMessage("Saved")
            setTimeout(() => {
                setValidMessage("")
            }, 3000);
            setCheckStatus(false)
            getCategoryList();
            setUser(props.user.id);
        })
    }

    const isSubmitDisabled = interestedCategory.length === 0 || favouriteCategory === null || closestMatch === null;


    return (
        pageLoading ?
            <div className="h-100 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
            </div> :
            <div className="personal-interest d-flex flex-column p-3 w-100">
            
                <div className="d-flex flex-column justify-content-center">
                    <div className="d-flex profile-background profile-image align-items-end justify-content-start" style={{ gap: "20px" }}>
                        <div className="d-flex justify-content-start align-items-end profile-background profile-image">
                            <div className="d-flex flex-row align-items-center justify-content-center profile-rounded">
                                { profileImage.imgSrc ?
                                    <img src={profileImage.imgSrc} alt="profile-image" style={{ height: '95px', width: '135px' }} /> :
                                    <img src={`${process.env.PUBLIC_URL}/static/icons/profile/default_2.svg`} alt="default" />
                                }
                            </div>
                        </div>
                        <div>
                            <div className="text-bold ml-3 fs-20">Please Share Your Interests, {props.user.firstName}</div>
                        </div>
                    </div>
                    <div className="w-100 p-3 fs-15">
                        <div>Let's personalize your CTBids experience to match your interests in less than 60 seconds.
                        You can always come back here to change what you select below whenever you like in the future.</div>
                        <p>Please choose at least one response for each column. Thank you for your help!</p>
                    </div>
                </div>
                <div className="d-flex w-100 mt-3">
                    <div style={{ width: '840px'}}>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="text-bold text-center fs-15 hd-ct-clr w-25 mobile-resp-pi" style={{ marginLeft: "24%" }}>Check ALL Categories <p>You Are Interested In</p></div>
                            <div className="text-bold text-center fs-15 hd-ct-clr w-25">Check One Category <p>That Is Your Favorite</p></div>
                        </div>
                        {
                            categoryList.map((category, index) => {
                                return (
                                    <div className="d-flex p-2 text-bold" key={index}>
                                        <div style={{ width: "37%", wordBreak: "break-word" }}>{category.category}</div>
                                        <div className="d-flex align-items-center justify-content-center w-25">
                                            <Form.Check 
                                                type="checkbox" 
                                                id={"interested-" + category.category} 
                                                name={category.category} 
                                                custom 
                                                checked={ interestedCategory.includes(category.category) ? true : false } 
                                                aria-label='interest' 
                                                className="mobile-pi-resp-checkbox"
                                                onChange={(e) => {handleInterestCheckboxChange(e)}} />
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center w-25">
                                            <Form.Check
                                                key={category.category}
                                                type="checkbox"
                                                id={"favourite-" + category.category}
                                                name={category.category}
                                                custom
                                                checked={favouriteCategory === category.category}
                                                aria-label="favourite"
                                                onChange={handleFavouriteCheckboxChange}
                                                className="mobile-pi-resp-checkbox"
                                            />

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="closest-match-container" >
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="text-bold text-center fs-15 hd-ct-clr closest-match" style={{ marginLeft: "20%" }}>Check The Closest <p>Match to You</p></div>
                        </div>
                        {
                            closestData.map((data, index) => {
                                return (
                                    <div className="d-flex p-2 text-bold" key={index}>
                                        <div className="" style={{ width: '50%', wordBreak: "break-word" }}>{data}</div>
                                        <div className="d-flex align-items-center justify-content-center" style={{ width: "20%" }}>
                                            <Form.Check 
                                            key={data}
                                            type="checkbox" 
                                            id={"close-" + data} 
                                            name={data} 
                                            custom 
                                            checked={closestMatch ===  data }
                                            aria-label='closest' 
                                            onChange={(e) => {handleClosestCheckboxChange(e)}} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="d-flex flex-column align-items-center mt-3">
                    {
                        validMessage ? <span className="text-success mb-3 text-bold"><i className="far fa-check-circle h5"></i> {validMessage}</span> : ''
                    }
                    <Button variant="secondary" disabled={interestedCategory.length === 0 || favouriteCategory === null || closestMatch === null || checkStatus} onClick={recordStatus ? updateCategory : submitCategory }>Submit Your Interests And Continue {checkStatus ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"></Spinner> : null}</Button>
                    { !props.user.interestCompleted ? <div className="mt-3 clickable text-bold" onClick={() => {navigateToPayment()}}><u>Or, Skip To Next Step &gt;</u></div> : '' }
                </div>
            </div>

    )
}

class PersonalInterest extends Component {
    constructor(props) {
        super(props);
        this.buyrId = this.props.user.id
    }

    componentDidMount() {
        store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
        ToggleSideBar();
        window.addEventListener('resize', ToggleSideBar);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', ToggleSideBar);
        removeSideBar();
    }

    render() {
        return <PersonalInterestFn {...this.props} buyerid = {this.buyerid} />
    }
}

export default connect(state => state)(PersonalInterest);
